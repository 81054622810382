<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>系统更新日志</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <div>
      <el-timeline>
        <el-timeline-item center timestamp="2023/12/01" placement="top">
          <el-card>
            <h4>问题反馈</h4>
            <p>钉钉联系人：宋旷</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2023/09/10" placement="top">
          <el-card>
            <h4>清理系统缓存(浏览器缓存)</h4>
            <p>在申请流程或者进销存功能模块中，如果有新添加的字典没有更新出来可以点击系统右上角的清理缓存刷新页面展示数据</p>
            <p>优化时间 2023/09/10</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2023/08/01" placement="top">
          <el-card>
            <h4>各渠道订单同步</h4>
            <p>系统自动同步各个渠道的订单并且支持各个平台的订单快递单打印等</p>
            <p>截至2023年09月支持小米有品/招行平台的自动化订单流程</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2023/06/01" placement="top">
          <el-card>
            <h4>办公系统报销流程</h4>
            <p>财务相关的报销流程电子化。目前支持费用报销/请款申请/借款申请等流程</p>
            <p>提出人 李莹 2023/05/15</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2023/04/20" placement="top">
          <el-card>
            <h4>盘点模块增加微信扫一扫功能</h4>
            <p>盘点模块支持微信扫码快速盘点；</p>
            <p>提出人 乔源涛 2023/04/20</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2023/03/17" placement="top">
          <el-card>
            <h4>入库模块增加多库房入库功能</h4>
            <p>入库模板新增库房列、入库列表增加库房下拉选择框；</p>
            <p>提出人 袁炯 2023/03/17</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2023/02/01-2023/03/15" placement="top">
          <el-card>
            <h4>抖音订单以及店管家抖音订单模块</h4>
            <p>提出人 李素慧 2023/02/01</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2022/6/1" placement="top">
          <el-card>
            <h4>快递费用计算模块</h4>
            <p>兼容接入顺丰各类型快递、圆通快递等快递计算以及出入库费用计算</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2021-2022" placement="top">
          ……
        </el-timeline-item>
        <el-timeline-item center timestamp="2021/10/1" placement="top">
          新版进销存上线
        </el-timeline-item>
        <el-timeline-item timestamp="2019/6/1" placement="top">
          项目启动及上线
        </el-timeline-item>
      </el-timeline>
    </div>

    <div style="margin-top:30px;">
      <el-input type="textarea" :rows="6" style="width: 98%"></el-input>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "version",
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() { },
};
</script>
<style lang="less">
.el-main h4 {
  margin-bottom: 10px;
}.el-main p {
  line-height: 1.3em;
}
</style>